import React from 'react';
import { connect } from 'react-redux';
import Form from './Form';
import { Col } from 'reactstrap';
import { Store } from 'redux';
import { ApplicationState } from '../../store';
import { insertarCubicacion, IndividualAction, fetchOs } from '../../store/individual/actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IndividualActionTypes } from '../../store/individual/types';
import { insertarCubicacionMessage } from '../../api/os';
import { push } from 'connected-react-router';
import { reset } from "redux-form";
import { useParams } from 'react-router-dom';

interface MainProps {
  store: Store<ApplicationState>;
}

export const Individual: React.FC<MainProps> = (props: any) => {
  let { barcode } = useParams();

  const searchClick = () => {
    props.store.dispatch(fetchOs(props.store.getState().form.individualForm.values.os));
    // props.store.dispatch(change('individualForm', 'os', ''));
  }

  const handleSubmit = (values: any) => {
    const numeroOS = Number(props.store.getState().individual?.data?.os?.ordenServicio?.numeroOS || 0) || 0;
    if (numeroOS === 0) {
      console.log('NO SE HA SELECCIONADO OS');
      props.store.dispatch({
        type: IndividualActionTypes.FETCH_ERROR,
        mensaje: 'NO SE HA SELECCIONADO OS'
      });
      return;
    }
    const modal: insertarCubicacionMessage = {
      alto: values.alto.toString(),
      ancho: values.ancho.toString(),
      codigoOficina: props.store.getState().login.user?.usuario?.codigoOficina?.toString(),
      codigoUsuario: props.store.getState().login.user?.usuario?.usuario?.toString(),
      largo: values.largo.toString(),
      listaOrdenesServicio: [
        {
          numeroOS: props.store.getState().individual.data.os.ordenServicio.numeroOS.toString(),
          especieValorada: props.store.getState().individual.data.os.ordenServicio.especieValorada.toString(),
          piezas: values.piezas.toString()
        }
      ],
      origen: "2",
      peso: values.peso.toString(),

    };
    props.store.dispatch(insertarCubicacion(modal));
  }

  props.store.subscribe(() => {
    if (props.store.getState().individual.message !== undefined || props.store.getState().individual.errors !== undefined) {
      if (props.store.getState().individual.message !== undefined) {
        toast.info(props.store.getState().individual.message);
      }
      if (props.store.getState().individual.errors !== undefined) {
        toast.error(props.store.getState().individual.errors);
      }
      const success: IndividualAction = {
        type: IndividualActionTypes.CLEAR_MESSAGE
      }
      props.store.dispatch(success);
    }
  });

  props.store.dispatch(reset('individualForm'));
  props.store.dispatch({
    type: IndividualActionTypes.FETCH_ERROR_FIND_OS,
    mensaje: undefined
  });

  // if (!props.store.getState().login.loggedin) {
  //   props.store.dispatch(push('/login'));
  // }

  return (
    <>
      <div>&nbsp;<ToastContainer /></div>
      <Col sm="11">
        <Form onSubmit={handleSubmit} onClick={searchClick} isLoading={props.store.getState().individual.loading} {...props} store={props.store} barcode={barcode} />
      </Col>
    </>
  );
}

export default connect(null)(Individual);