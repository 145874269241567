import { LoginTypes } from "./types";
import { Action, Dispatch, ActionCreator, AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../index";
import {doToken, LoggedUser, loginUser} from '../../api/login';
import { LoginEntity } from "../../models/login";
import { createBrowserHistory } from 'history';
import { push } from 'connected-react-router';

export const browserHistory = createBrowserHistory();

export type AppThunk = ActionCreator<
  ThunkAction<Action<LoginTypes>, ApplicationState, AnyAction, AnyAction>
>;
export const logout: AppThunk = () => {
  return (dispatch: Dispatch): Action => {
    return dispatch({
      type: LoginTypes.LOGOUT
    });
  };
};
export interface LoginAction extends Action<LoginTypes> {
  payload?: LoggedUser,
  error?: string
}
export const doLogin: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<LoginTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<LoginTypes>
>> = (loginForm: LoginEntity) => {
  console.log('ACTION LOGIN');
  return async (dispatch: Dispatch) => {
    const loading: LoginAction = {
      type: LoginTypes.LOGIN_LOADING
    }
    dispatch(loading);
    try {
      /*var token = await doToken(loginForm);
      if (!token.access_token) {
        const loginError: LoginAction = {
          type: LoginTypes.LOGIN_ERROR,
          error: "Error al hacer login",
        };
        return dispatch(loginError);
      } else {
        localStorage.setItem("token",token.access_token);
      }*/
      localStorage.setItem("token", "hola");
      var loggedUser = await loginUser(loginForm);
      console.log(loggedUser);
      if (loggedUser.error.codigo === 0) {
        console.log('LOGIN OK');
        const login: LoginAction = {
          type: LoginTypes.LOGIN,
          payload: loggedUser,
        };
        dispatch(login);
        dispatch(push('/individual'));
        return dispatch(login);
      } else {
        const loginError: LoginAction = {
          type: LoginTypes.LOGIN_ERROR,
          error: "Error al hacer login",
        };
        return dispatch(loginError);
      }
    } catch (Error) {
      console.log('Error al conectarse a la API');
      const loginError: LoginAction = {
        type: LoginTypes.LOGIN_ERROR,
        error: "Error al hacer login",
      };
      return dispatch(loginError);
    }

  };
};

export const doLogout: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Action<LoginTypes>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<LoginTypes>
>> = () => {
  console.log('ACTION LOGOUT');
  return (dispatch: Dispatch): Action => {
    const loginError: LoginAction = {
      type: LoginTypes.LOGIN_ERROR,
      error: "Error al hacer login",
    };
    return dispatch(loginError);
  };
};
